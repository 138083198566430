import auth0 from 'auth0-js'

export function getAuth0() {
  return new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN||"",
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID||"",
    redirectUri:process.env.REACT_APP_REDIRECT||'',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',    
    scope: 'openid profile email',
    responseType: 'token id_token',

  });
}