import { Auth0Callback, Auth0DecodedHash, Auth0ParseHashError, CrossOriginLoginOptions, WebAuth } from 'auth0-js';
import React from 'react'
import { Observable, map,concatAll, Subscriber, Subject, ReplaySubject, switchMap } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { getAuth0 } from '../utils/auth0'
import { IAuthContext, IAuthLoginResponse, IAuthResponse} from './AuthContext.d'
import * as Sentry from "@sentry/react";



const silentAuthAlternative = (subscriber:Subject<IAuthResponse>, webAuth:WebAuth)=> {
  try{
    const checkSessionParams:Record<string,string> = {}
    const token = localStorage.getItem("accessToken")
    if(token){
      checkSessionParams['accessToken'] = token
      checkSessionParams['responseType'] = "id_token token"
      checkSessionParams['audience'] = process.env.REACT_APP_AUTH0_AUDIENCE || ''
      checkSessionParams['scope'] ='openid profile email'
    }
    webAuth.checkSession(checkSessionParams,processResponse(subscriber,webAuth))
  }catch(err){    
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Silent authentication fail by error",
      level: Sentry.Severity.Info,
    });
    Sentry.captureException(err)
    subscriber.next({authenticated:false})
    subscriber.complete()
  }
}

const processResponse=(subscriber:Subject<IAuthResponse>, webAuth:WebAuth, alternative?:Function):Auth0Callback<Auth0DecodedHash | null, Auth0ParseHashError>=> (err:any,user:Auth0DecodedHash | null)=>{
  if(err){    
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Authentication fail by error",
      level: Sentry.Severity.Info,
    });
    //If error then is not authenticated    
    subscriber.next({authenticated: false})        
    subscriber.complete()
  }else if(user?.accessToken && user?.idToken){      
    //console.log(user)  
    const username =user.idTokenPayload?.email    
    const useMFA = user.idTokenPayload.use_mfa || false;
    Sentry.setUser({ email: username });    
    // @ts-ignore
    if(window.FS){
      // @ts-ignore
      window.FS.identify(user.idTokenPayload.sub, {email:username});
    }
    //sessionStorage.setItem("realm",  user. || "");  
    //If authenticated    
    localStorage.setItem("accessToken", user.accessToken)
    localStorage.setItem("jwtToken", user.idToken)    
    localStorage.setItem('useMFA', useMFA.toString())    
    //localStorage.removeItem("calculator:params")
    subscriber.next({authenticated: true})
    subscriber.complete()
  }else if(alternative){
    alternative(subscriber, webAuth)
  }else{
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Authentication fail",
      level: Sentry.Severity.Info,
    });
    subscriber.next({authenticated: false})
    subscriber.complete()
  }
}
const authenticate$ =new ReplaySubject<IAuthResponse>()
export const initialAuth:IAuthContext = {
  login: ($events) => $events.pipe(map(({username, email, password}) => new Observable<IAuthLoginResponse>((subscriber)=> {
    const webAuth = getAuth0()
    const options:CrossOriginLoginOptions  ={      
      password,
      audience:process.env.REACT_APP_AUTH0_AUDIENCE,
      responseType: 'id_token token'
    }
    if(username){
      options.realm = process.env.REACT_APP_AUTH0_REALM_USERNAME;
      options.username = username;      
    }
    if(email){
      options.realm = process.env.REACT_APP_AUTH0_REALM;
      options.email = email;
    }
    localStorage.setItem("realm",  options.realm || "");  
    webAuth.login(options,(err, user)=>{      
      if(err){ 
        Sentry.captureException(err)
        Sentry.addBreadcrumb({
          category: "auth",
          message: "Authentication fail by error "+err.description,
          level: Sentry.Severity.Info,
        });       
        subscriber.next({error:err.description})
        subscriber.complete()
    }else{      
        Sentry.setUser({ email: username });
        // @ts-ignore
        if(window.FS){
          // @ts-ignore
          window.FS.identify(user.idTokenPayload.sub, {email:username});
        }
        subscriber.next()        
        subscriber.complete()
      }
    })
  })), concatAll()),
  requestAuthenticate: ()=>{
    
    const webAuth = getAuth0()    
    //Check authorization code if there is no code, try silent authentication
    webAuth.parseHash({ hash: window.location.hash }, processResponse(authenticate$, webAuth, silentAuthAlternative))                    
  },  
  authenticate: authenticate$,
  /*silentAuthenticate: new Observable<IAuthResponse>((subscriber)=>{
    const webAuth = getAuth0()
    silentAuthAlternative(subscriber,webAuth)
  }),*/
  signup: ($events) => $events.pipe(map( ({username, email, firstName, lastName, password,pod, photo, useMFA}) => {    
    const formData = new FormData();
    if(username){
      formData.append('username', username);
    }
    if(email){
      formData.append('email', email);
    }
    if(firstName){
      formData.append('firstName', firstName);
    }
    if(lastName){
      formData.append('lastName', lastName);
    }
    formData.append('password', password);
    formData.append('useMFA', useMFA.toString());
    formData.append('pod', pod);
    if(photo){
      formData.append('avatar', photo);
    }
    return ajax<IAuthLoginResponse>({
      url: `${process.env.REACT_APP_API_BASE_URL}/footprint/signup`,
      method: 'POST',
      body: formData
    })    
  }), concatAll()).pipe(map(response=> response.response))
  ,logout: (isUsername)=> {
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Logout",
      level: Sentry.Severity.Info,
    });
    const webAuth = getAuth0() 
    localStorage.removeItem("jwtToken")
    localStorage.removeItem("useMFA")
    localStorage.removeItem("accessToken")    
    localStorage.removeItem("calculator:params")
    webAuth.logout({
      returnTo: isUsername ? process.env.REACT_APP_RETURN_USERNAME :process.env.REACT_APP_RETURN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
    });        
  },
  forgotPassword: ($events) => $events.pipe(map( (  {username}) => {    
    const webAuth = getAuth0()    
    const result = new Subject<boolean>()
    webAuth.changePassword({
      connection: process.env.REACT_APP_AUTH0_REALM||"",
      email: username
    }, function(err){
      if(err){
        result.next(false)
      }else{
        result.next(true)
      }
    });
    return result;
  }), concatAll())
};


const AuthContext = React.createContext<IAuthContext>(initialAuth);
export default AuthContext;