import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useContext, useState } from "react";
import { ILocation, IQuestionZipcode } from "../context/CalculatorContext.d";
import CalculatorContext from "../context/CalculatorContext";
import QuestionText from "./QuestionText";
import { useObservableState, useSubscription } from "observable-hooks";
import { OutlinedInput, TextField } from "@material-ui/core";
import './QuestionZipcode.css'
interface IQuestionZipcodeProps{
  value?: ILocation
  onChange: (value:any)=>void
  question: IQuestionZipcode
}
export default function QuestionZipcode(props:IQuestionZipcodeProps){
  const [value, setValue] = useState<ILocation|null>(props.value || null)
  const [inputValue, setInputValue] = useState("")
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ILocation[]>([]);

  const calculatorContext = useContext(CalculatorContext)    
  const loading = open && options.length === 0;
  useSubscription(calculatorContext.location$,(response)=>{
    setOptions(response.locations)
  })
  const onChange = (ev: React.ChangeEvent<{}>, value: ILocation|null)=>{
    if(value){      
      setValue(value)
      props.onChange(value)
    }
  }
  const handleChangeZipcode =(ev:React.ChangeEvent<HTMLInputElement>)=>{    
    setOptions([])    
    if(ev.target.value.length >3){
      calculatorContext.getLocation({
        zipcode: ev.target.value
      })
    }
    setInputValue(ev.target.value)
  }
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);    
  return(
    <Autocomplete    
      value={value}  
      className="question-zipcode"      
      loading={loading}
      options={options}      
      onChange={onChange}
      filterOptions={(options)=>options}
      getOptionLabel={(location) => location.label || ""}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}      
      renderInput={(params) => (        
        <TextField {...params} value={inputValue} placeholder="Enter your zipcode" onChange={handleChangeZipcode} variant="outlined"  />
      )}
    />
  )
  }