import { MouseEventHandler, useCallback, useContext,useState, useRef, useEffect, useMemo } from 'react'
import { useObservableCallback,useObservableState } from "observable-hooks"
import { useHistory } from "react-router-dom"
import CalculatorContext from '../context/CalculatorContext';
import {IDirtyParam} from '../context/CalculatorContext.d';
import QuestionText from './QuestionText'
import './Question.css'
import { Button, Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { IQuestionType, UNITS, UNITS_LABEL } from '../constant/questions';
import QuestionChoice from './QuestionChoice';
import ReactMarkdown from 'react-markdown'
import HintDefault from './HintDefault';
import QuestionHelper from './QuestionHelper';
import QuestionVehicles from './QuestionVehicles';
import QuestionMultiple from './QuestionMultiple';
import QuestionAdvance from './QuestionAdvance';
import QuestionZipcode from './QuestionZipcode';
import QuestionSlider from './QuestionSlider';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

enum QUESTION_ERRORS {
  PLEASE_SKIP,
  PLEASE_SELECT
}

const ERROR_MESSAGES = {
  [QUESTION_ERRORS.PLEASE_SKIP]: `Please enter a value or click “skip question” .`,
  [QUESTION_ERRORS.PLEASE_SELECT]: "Please provide a valid value."
}

export default function Question(){
  const calculatorContext = useContext(CalculatorContext)
  const component = useRef()
  const history = useHistory()
  const status = useObservableState(calculatorContext.status$)
  const calculation = useObservableState(calculatorContext.calculation$, calculatorContext.getPreviousCalculation())
  const defaultValues = calculation?.defaults
  const [dirtyParam, setDirtyParam] = useState<IDirtyParam|null>(null)
  const [dirtyParam2, setDirtyParam2] = useState<IDirtyParam|null>(null)
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLDivElement>(null);
  const [errorCode, setErrorCode] = useState<QUESTION_ERRORS|null>(null);
  const [desiredScreen, setDesiredScreen] = useState<string|undefined>(undefined);

  const canBeSkipped = useMemo(()=>{
    return !status?.question.hideDefaultHint && status?.question.default !== undefined
  },[status?.question.hideDefaultHint, status?.question.default])

  const submit = (allowForce:boolean)=>{    
    setErrorCode(null);
    if(allowForce && status?.question.default !== undefined){
      calculatorContext.updateParam(status.question.slug, {...status?.question.default, ...{skipped:true} }, (nextSlug)=>{
        next(nextSlug)
      })
    }else if(dirtyParam && dirtyParam2 && status?.question.slug){      
      calculatorContext.updateParams( [dirtyParam,dirtyParam2],(nextSlug)=>{
        next(nextSlug)
      })
    }else if(dirtyParam && status?.question.slug){      
      calculatorContext.updateParam(status.question.slug, dirtyParam.value,(nextSlug)=>{
        next(nextSlug)
      })
      //next()
    }else if(status?.question.slug && calculatorContext.calculatorParams[status.question.slug] && !calculatorContext.calculatorParams[status.question.slug].skipped){
      calculatorContext.updateParam(status.question.slug, calculatorContext.calculatorParams[status.question.slug],(nextSlug)=>{
        next(nextSlug)
      })
      //next()
    }else if(status?.question.hideDefaultHint && status?.question.default !== undefined){
      calculatorContext.updateParam(status.question.slug, {...status?.question.default }, (nextSlug)=>{
        next(nextSlug)
      })
    }else if(canBeSkipped){
      setErrorCode(QUESTION_ERRORS.PLEASE_SKIP)
    }else{
      setErrorCode(QUESTION_ERRORS.PLEASE_SELECT)
    }
  }
  const onChange = (value:any)=>{    
    setErrorCode(null);    
    if(status?.question.slug){            
      setDirtyParam({
        key: status.question.slug,
        value: {...value}
      })
    }
    
    if(status?.question.type === IQuestionType.CHOICE && status?.question.canSkipNext && status.skippableOptions){      
      if(status.skippableOptions.includes(value.selected)){
        setDirtyParam2({
          key: status.question.canSkipNext,
          value: {value:0}
        })
      }
    }
  }
  const next = (newNext?:string)=>{    
    setErrorCode(null);
    setDesiredScreen(newNext)
    //setDirtyParam(null)
    if(status && status.next=='COMPLETE'){
      history.push(`/calculator/footprint`)
    }else if(newNext){      
      history.push(`/calculator/question/${newNext}`)
    }else if(status){
      history.push(`/calculator/question/${status.next}`)
    }   
  }
  const previous = ()=>{ 
    setErrorCode(null);   
    //setDirtyParam(null)
    if(status && status.previous!='FIRST'){
      setDesiredScreen(undefined)
      history.push(`/calculator/question/${status.previous}`)
    }
  }
  const changeUnits = ()=>{
    const units = calculatorContext.calculatorParams['units'] || UNITS.STANDARD
    let newUnits:UNITS = units
    if(units==UNITS.METRIC){
      newUnits = UNITS.STANDARD
    }else{
      newUnits = UNITS.METRIC      
    }    
    if(dirtyParam && status?.question.slug){      
      calculatorContext.updateMetric(newUnits, status.question.slug, dirtyParam)      
    }else{
      calculatorContext.updateMetric(newUnits)
    }
  } 
  const handleDefault = ()=>{
    setErrorCode(null);
    let defaultValue = defaultValues && status ?defaultValues[status.question.slug]:undefined
    if(defaultValue && defaultValue.value){
      defaultValue = JSON.parse(JSON.stringify(defaultValue))
      onChange({        
        ...defaultValue,
        value: Number(defaultValue.value).toFixed(2)
      })
    }
  }
  let currentValue = undefined
  if(status){
    currentValue = dirtyParam?.value || calculatorContext.calculatorParams[status.question.slug]
  }    
  const currentUnits:UNITS = calculatorContext.calculatorParams['units'] || UNITS.STANDARD   
  
  const goToFootprint = (section:string) =>{
    history.push(`/calculator/question/${section}`)
    setMenuOpen(false)
  }

  useEffect(()=>{
    setDirtyParam(null)
    setDirtyParam2(null)
  }, [history.location])

  const isTransitioning = useMemo(()=>{
    if(!desiredScreen  || !status?.question ){
      return false;
    }
    //console.log(desiredScreen, status?.question.slug)
    return desiredScreen !== status?.question.slug
  },[desiredScreen, status?.question])

  const isNavigationDisabled = useMemo(()=>{
    if (isTransitioning){
      return true
    }
    if (status?.question){
      return status.question.disableNavigation && !calculatorContext.calculatorParams[status.question.slug]
    }
    return false
  },[status?.question, calculatorContext.calculatorParams, isTransitioning])
  
  return (
    <div className="calculator_question">
      <div className="wrapper">
      <header>
        {status?.question.question}
      </header> 
      {status?.question.info && 
        <Paper className="info" elevation={0}>
          <ReactMarkdown linkTarget="_blank">
            {status.question.info}
          </ReactMarkdown>
        </Paper>        
      }      
      {status?.question.type == IQuestionType.ZIPCODE && (
        <QuestionZipcode  value={currentValue} key={status.question.slug + dirtyParam?.key} question={status.question} onChange={onChange} />
      )}
      {status?.question.type == IQuestionType.TEXT && (
        <QuestionText  units={currentUnits} helpers={status.question.helpers}  value={currentValue} key={status.question.slug} type="text" placeholder={status.question.placeholder} helperPlaceholder={status.question.helperPlaceholder} onChange={onChange}/>
      )}
      {(status?.question.type == IQuestionType.CHOICE  || status?.question.type == IQuestionType.CHOICETEXT) && (
        <QuestionChoice value={currentValue} key={status.question.slug} question={status.question} onChange={onChange} type={status.question.type}/>    
      )}
      {status?.question.type == IQuestionType.NUMBER && (
        <QuestionText min={status.question.min } max={status.question.max} useDropdown={status.question.useDropdown} conversion={status.question.conversion} units={currentUnits} helperPlaceholderUnit={status.question.helperPlaceholderUnit} helpers={status.question.helpers} value={currentValue} key={status.question.slug} type="number" placeholder={status.question.placeholder} helperPlaceholder={status.question.helperPlaceholder} onChange={onChange}/>
      )}
      {status?.question.type == IQuestionType.VEHICLES && (
        <QuestionVehicles 
            default={defaultValues?defaultValues[status.question.slug]:undefined} 
            units={currentUnits}
            key={status.question.slug} 
            value={currentValue} 
            onChange={onChange}
            tooltipDriven={status.question.tooltips ? status.question.tooltips['vehicle-driven']: undefined}
            tooltipEfficiency={status.question.tooltips ? status.question.tooltips['vehicle-efficiency']: undefined}
            />
      )}
      {status?.question.type == IQuestionType.MULTIPLE && (
        <QuestionMultiple needConversionDefault={Boolean(status.question.conversion)} default={defaultValues?defaultValues[status.question.slug]:undefined} units={currentUnits} key={status.question.slug} question={status.question} value={currentValue} onChange={onChange}/>
      )}
      {status?.question.type == IQuestionType.NUMBER_ADVANCE && (
        <QuestionAdvance  default={defaultValues?defaultValues[status.question.slug]:undefined} key={status.question.slug} question={status.question} value={currentValue} onChange={onChange}/>
      )}
      {status?.question.type == IQuestionType.SLIDER && (
        <QuestionSlider formatLabel={status.question.formatLabel} format={status.question.format} defaultValue={status.question.default?.value || 0} min={status.question.min} max={status.question.max} steps={status.question.steps} value={currentValue} onChange={onChange}/>
      )}
      {status?.question.hintDefault && (
        <HintDefault onClick={handleDefault} />
      )}
      <Button onClick={()=>submit(false)} className="submit" variant="contained" color="primary">  
        CONTINUE
      </Button>
      {errorCode != null &&
        <div className='errorQuestion'>
          {ERROR_MESSAGES[errorCode]}
        </div>
      }      
      {canBeSkipped && 
        <div className='skiplink' onClick={()=>submit(true)} >
          Skip question
        </div>
      }
      </div>
      <footer>
        <div ref={anchorEl} className="navigation-buttons">
          <Button className="nav-btn-prev" onClick={previous} disabled={!Boolean(status?.previous !='FIRST') } variant="contained" color="secondary" disableElevation><ArrowBackIosIcon/></Button>
          {/*
          <Button disabled={isNavigationDisabled} className="nav-btn-menu" onClick={()=>setMenuOpen(true)}  variant="contained" color="secondary" disableElevation><MenuIcon/></Button>          
          */}
          <Button disabled={isNavigationDisabled} className="nav-btn-next" onClick={()=>next() } variant="contained" color="secondary" disableElevation><ArrowForwardIosIcon/></Button>
        </div>
        
        <div className="aux-buttons">
          <Button className="units" variant="outlined" color="default" onClick={changeUnits} disableElevation>
            <img src="/assets/images/ruler.svg"/>&nbsp;
            <span className="description">{UNITS_LABEL[currentUnits]}</span>
            </Button>
          <Button className="need-help" variant="contained" color="secondary" disableElevation href="https://www.thecarbonauts.com/help" target="_blank">Help?</Button>
        </div>
        <div className="coolclimate">
          Powered by <a href="https://coolclimate.berkeley.edu/" target="_blank">Coolclimate</a>
        </div>
      </footer>
      <Popper open={menuOpen} anchorEl={anchorEl.current} role={undefined} transition disablePortal>          
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={()=>setMenuOpen(false)}>
                    <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                    {status?.sections.map((section, index) =>                        
                      <MenuItem onClick={()=>goToFootprint(section.firstSlug)}>{section.label}</MenuItem>                  
                    )}                  
                      <MenuItem onClick={()=>history.push(`/calculator/footprint`)}>Footprint Results</MenuItem>                  
                    </MenuList>
                  </ClickAwayListener>
                  </Paper>
              </Grow>
            )}
        </Popper>
    </div>
  )
  /*
  switch(status?.question.type){
    default:
    case IQuestionType.TEXT:
      return <QuestionText />
    case IQuestionType.NUMBER:
        return <QuestionNumber />
    case IQuestionType.CHOICE:
        return <QuestionChoice />
  }*/
}