import './Signup.css'
import { FormControl,Input, Button, Paper, TextField, Icon,FormControlLabel, Checkbox, IconButton, Tooltip, Snackbar } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useCallback,useContext, useRef, useState } from 'react';
import {useHistory} from 'react-router-dom'
import { useObservableCallback, useSubscription } from 'observable-hooks';
import AuthContext from '../context/AuthContext';
import {IAuthContext, IAuthLoginResponse, IAuthRequest} from '../context/AuthContext.d'
import { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import ShortUniqueId from 'short-unique-id';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';

interface IPasswordErrorsProps{
  errors: Array<string>
}
interface SignupProps {
  forceMFA: boolean
}

function PasswordErrors ({errors}:IPasswordErrorsProps){
  return (<>
    {errors.map((error)=>(
      <div>
        {error}
      </div>
    ))}
    </>)
}

function checkText(text:string){
  const findNumbers = /\d/;
  if(findNumbers.test(text)){
    return "Cannot include numbers"
  }
  return null
}

function SignupUsername({forceMFA=false}:SignupProps) {
  const uid = new ShortUniqueId({dictionary: "number", length: 8});

  const fileInput = useRef<HTMLInputElement>(null)
  const authContext = useContext(AuthContext)
  const history = useHistory();
  const [onSignup, signupChange$] = useObservableCallback(authContext.signup)
  const [onLogin, loginChange$] = useObservableCallback<IAuthLoginResponse, IAuthRequest>(authContext.login)  
  const [username, setUserName] = useState(uid())  
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [checked, setChecked] = useState(false)

  const [passwordError, setPasswordError] = useState<string|null>(null)  

  const [checkError, setCheckError] = useState<string|null>(null)  
  
  const [pod, setPod] = useState('')
  const [photo, setPhoto] = useState<FileList | null>(null)
  const [loading, setLoading] = useState(false)
  const [userError, setUserError] = useState<string|null>(null)
  const [useMFA, setUseMFA] = useState<boolean>(forceMFA);
  const [openCopy, setOpenCopy] = useState<boolean>(false);
  const defaultEmailMessage = (<div style={{color:"rgb(0, 0, 0,0.68)"}}>
    {"Your username, above, is a randomly generated number created to protect your identity. Please save it as it cannot be recovered."}
  </div>)
  const defaultPassword = (<div style={{color:"rgb(0, 0, 0,0.68)"}}>
  {"Use 8 or more characters with a mix of letters and numbers. Again, save your password along with your username (the random number above) as they can't be recovered."}
  </div>)
  const handleSignup = useCallback(()=>{    
    if(!checked){
      setCheckError("Please accept the terms of use.")
      return
    }
    setCheckError(null);    
    setUserError(null)
    setPasswordError(null)

    
    if(!username){
      setUserError("Enter a valid username")
      return
    }
    if(!password){
      setPasswordError("Enter a password")
      return
    }
    if(password != confirm){
      setPasswordError("Password doesn't match")
      return
    }
    setLoading(true)
    onSignup({
      email:null,
      username,
      firstName:null,
      lastName:null,
      password,
      pod,
      photo: photo && photo.length > 0 ? photo[0] : undefined,
      useMFA
    })
  },[username, password, pod, photo, confirm, checked, useMFA, onSignup])
  const handleChecked = useCallback((event)=>{
    setChecked(event.target.checked);
    setCheckError(null);
  },[setChecked, setCheckError])
  const handlePhotoSelect = useCallback(()=>{
    fileInput.current?.click()    
  },[fileInput])
  useSubscription(signupChange$,({error, messages,errorPassword})=>{    
    if(error){
      setLoading(false)      
      if(messages && messages['email']){
        setUserError(messages['email'].join(''))
        return
      }
      setUserError(error)
    }else if(errorPassword){
      setPasswordError(errorPassword)
    }else{
      onLogin({username, password})
    }
    
  },()=>{
    setLoading(false)
    setUserError("Error in signup please try again")
  })
  useSubscription(loginChange$,({error})=>{
    setLoading(false)
    if(error){
      setUserError(error)
    }
  })
  useEffect(()=>{
    if(!password || !confirm){
      setPasswordError(null)
      return
    }else if(password != confirm){
      setPasswordError("Password doesn't match")      
    }else{
      setPasswordError(null)
    }
  },[confirm,password])
  const copyUsername = ()=>{
    navigator.clipboard.writeText(username);
    setOpenCopy(true)
  }
  const handleLogin = useCallback(()=>{
    history.push('/login')
  },[])
  const handleCloseCopy = useCallback(()=>{
    setOpenCopy(false)
  },[setOpenCopy])

  const passwordErrorElement = passwordError? <PasswordErrors errors={passwordError.split('\n')}/> : null  
  //console.log(forceMFA, useMFA)
  return (
	<>
		<nav className="nav_component">
		  <header>
		    <div className="nav_logo">
		      <img src="/assets/images/Logo.svg" />
		    </div>
			</header>
		</nav>
		<div className="screen_signup">
      <div className="wrapper">
        <h1>Sign up to get started with<br/>The Carbonauts Carbon Calculator</h1>
        <form>          
          <FormControl margin="normal">
            <TextField  InputProps={{ style:{fontSize:24, color:"#000"},  
                endAdornment: <InputAdornment position="end">
                   <Tooltip title="Copy username to clipboard">
                   <Button  
                   style={ {
                    fontSize: 16,
                    fontWeight: "bold",
                    }}
                  onClick={copyUsername}
                >
                  Copy
                </Button>
                </Tooltip>
                </InputAdornment>
            }} 
                error={Boolean(userError)} helperText={userError || defaultEmailMessage} 
                label='Username' value={username} onChange={(ev)=>setUserName(ev.target.value)} variant="outlined" required/>
            <div id="mask" style={{position:"absolute", width:"calc(100% - 78px)", height:"70px"}}></div>

          </FormControl>          
            {/*<FormControl margin="normal">
              <TextField label='Pod' value={pod} onChange={(ev)=>setPod(ev.target.value)} variant="outlined" required/>
            </FormControl>*/}
          {/*
            <Button variant="outlined" className="profile-button"  onClick={handlePhotoSelect} endIcon={<CloudUploadIcon/>}>Upload a profile photo</Button>                    
            <input ref={fileInput} type="file" className="hide" onChange={ev => setPhoto(ev.target.files)}/>
          */}
          <div className="double-field">
            <FormControl margin="normal">
              <TextField error={Boolean(passwordError)} type="password" label='Password' value={password} onChange={(ev)=>setPassword(ev.target.value)} variant="outlined" required/>
            </FormControl>
            <FormControl margin="normal">
              <TextField type="password" label='Confirm Password' value={confirm} onChange={(ev)=>setConfirm(ev.target.value)} variant="outlined" required/>
            </FormControl>
          </div>
          {!Boolean(passwordError) ?
            <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled Mui-required">{defaultPassword}</p>          
          : (
            <div className='MuiFormHelperText-root Mui-error MuiFormHelperText-contained MuiFormHelperText-filled Mui-required'>
              {passwordErrorElement}
            </div>
          )}
          
          <div className="terms">            
          <Checkbox
          
        checked={checked}
        onChange={handleChecked}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <span>
            I acknowledge that I agree to the <a target="_blank"  rel="noreferrer" href="https://www.thecarbonauts.com/terms-of-use">Terms of Use</a> and have read the <a target="_blank"  rel="noreferrer" href='https://www.thecarbonauts.com/privacy-policy'>Privacy Policy.</a>
            </span>
          </div>
          {Boolean(checkError) ? (<div className='MuiFormHelperText-root Mui-error MuiFormHelperText-contained MuiFormHelperText-filled Mui-required'>
              {checkError}
            </div> ):null
          }
        </form>
        <Alert style={{marginTop:10, marginBottom:10, border:"2px solid #f9d2bc"}} severity="warning">
          <b>IMPORTANT:</b> Save your username (random number at top of page) and password in a safe place as they cannot be reset or recovered. 
          <br/>
          <br/>
          No, really! Save them somewhere safe now, please.
        </Alert>
        <Button disabled={loading} variant="contained"  color="primary" onClick={handleSignup} className="primary">
          {loading?
          <>
          <CircularProgress color='inherit' style={{marginRight:10}} size={20} />
          Loading...
          </>
          :"Sign up"}
        </Button>
        
        <footer>
          Already have an account? <a onClick={handleLogin}>Log in</a>
        </footer>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openCopy}
        autoHideDuration={2000}
        onClose={handleCloseCopy}
        message="Username copied to clipboard"
        action={
          <>            
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseCopy}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        />
    </div>
	</>
  );
}

export default SignupUsername;