import './Login.css'
import { FormControl,Input, Button, Paper, TextField, Snackbar, IconButton, SnackbarCloseReason,Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import {useHistory} from 'react-router-dom'
import AuthContext from '../context/AuthContext';
import { useObservableCallback, useSubscription } from 'observable-hooks';
import { IAuthLoginResponse, IAuthRequest, IForgotRequest } from '../context/AuthContext.d';
import CloseIcon from '@material-ui/icons/Close';
import { setUser } from '@sentry/react';
function LoginUsername() {
  const [open, setOpen] = useState(false);

  const authContext = useContext(AuthContext)  
  const history = useHistory();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const defaultUserMessage:React.ReactNode = (<div style={{color:"rgb(0, 0, 0,0.68)"}}>
  {"Your username is a randomly generated number that was assigned to you when you created an account."}  
  </div>)
  const [userError, setUserError] = useState<boolean>(false)
  const [userMessage, setUserMessage] = useState<React.ReactNode|null>(defaultUserMessage)
  const [passwordError, setPassswordError] = useState<string|null>(null)
  const [loading, setLoading] = useState(false)
  const [onLogin, loginChange$] = useObservableCallback<IAuthLoginResponse, IAuthRequest>(authContext.login)
  const [onForgot, forgotChange$] = useObservableCallback<boolean, IForgotRequest>(authContext.forgotPassword)

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  useSubscription(loginChange$,({error})=>{
    setLoading(false)
    if(error){
      setUserMessage(error)
      setUserError(true)
    }
  })
  useSubscription(forgotChange$, (result)=>{
    setLoading(false)
    if(!result){
      setUserMessage("There was an error requesting your password change")
      setUserError(true)
    }else{
      setOpen(true)
    }
  })
  const handleSignup = useCallback(()=>{
    setUserMessage(defaultUserMessage)
    setUserError(false)
    setLoading(false)
    history.push('/signup')
  },[setUserError, setUserMessage])
  const handleLogin = useCallback(()=>{
    if(!username){      
      setUserMessage("Enter an email")
      setUserError(true)
      return
    }
    if(!password){
      setPassswordError("Enter a password")
      return
    }
    setUserMessage(defaultUserMessage)
    setUserError(false)
    setPassswordError(null)
    setLoading(true)
    onLogin({username,password})
  },[username,password,onLogin,setUserError, setUserMessage, defaultUserMessage])
  const handleForgot = ()=>{
    setOpenDialog(true)
  }
  const handleClose = (event:React.SyntheticEvent<any>, reason:SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const checkEnterAndLogin = (ev:React.KeyboardEvent<HTMLInputElement>) =>{
    if(ev.key === 'Enter'){
      handleLogin()
    }
  }
  const handleLoginEmail = () => {
    setUserMessage(defaultUserMessage)
    setUserError(false)
    setLoading(false)
    history.push('/login')
  }
  const handleCloseDialog = () =>{ 
    setOpenDialog(false)
  }

  return (
	<>
		<nav className="nav_component">
		  <header>
		    <div className="nav_logo">
		      <img src="/assets/images/Logo.svg" />
		    </div>
			</header>
		</nav>
		<div className="screen_login">
      <div className="wrapper">
        <h1>Log in to get started</h1>
        <form>
          <FormControl margin="normal">
            <TextField error={userError} helperText={userMessage} value={username} onChange={(ev)=>setUsername(ev.target.value)} label='Username' variant="outlined" required onKeyPress={checkEnterAndLogin}/>
          </FormControl>          
          <FormControl margin="normal">
            <TextField error={!!passwordError} helperText={passwordError} type="password" value={password} onChange={(ev)=>setPassword(ev.target.value)} label='Password' variant="outlined" required onKeyPress={checkEnterAndLogin}/>
          </FormControl>          
        </form>
        <Button disabled={loading} variant="contained" color="primary" onClick={handleLogin} className="primary">Log in</Button>
        <footer>
          Need to sign up? <a onClick={handleSignup}>Create an account</a>
        </footer>                            
        <footer>
          Need help? Email <a style={{color:"#000"}} href="mailto:info@thecarbonauts.com" target="_blank">info@thecarbonauts.com</a>
        </footer>                            
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="If you have an account you have received an email with instructions to reset your password"
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>setOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        />
        
    </div>
  </>
	);
}

export default LoginUsername;