import './QuestionHelper.css'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButton } from '@material-ui/lab';
import { IQuestionSelectChoice } from '../context/CalculatorContext.d';
import { useEffect, useState } from 'react';
import { UNITS } from '../constant/questions';

interface IQuestionHelperProps{
  choices: IQuestionSelectChoice[]  
  onChange: (value:IQuestionSelectChoice)=>void
  value: IQuestionSelectChoice
  units?: UNITS
}
const getLabel =(choice:IQuestionSelectChoice, unit?:UNITS)=>{  
  if(unit && choice.labelUnit){
    return choice.labelUnit[unit]
  }
  return choice.label
}

export const getPlaceholder =(choice?:IQuestionSelectChoice, unit?:UNITS)=>{  
  if(unit && choice?.placeholderUnit){
    return choice.placeholderUnit[unit]
  }
  return choice?.placeholder
}

export default function QuestionHelper(props:IQuestionHelperProps){
  const {choices, value, units} = props   

  const onChange = (ev:React.MouseEvent, newValue:IQuestionSelectChoice)=>{
    if(newValue){
      props.onChange(newValue)
    }
  }  
  return (
    <ToggleButtonGroup
      className="question-helper"
      value={value || choices[0]}
      color="primary"
      exclusive
      onChange={onChange}
      aria-label="text alignment"
    >
      {choices && choices.map((choice,index)=>
        <ToggleButton key={index} selected={choice.value == (value?.value || choices[0].value)} value={choice} aria-label={getLabel(choice, units)}>
          {getLabel(choice, units)}          
        </ToggleButton>
      )}                  
    </ToggleButtonGroup>
  )
}