import './Signup.css'
import { FormControl,Input, Button, Paper, TextField, Icon,FormControlLabel, Checkbox } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useCallback,useContext, useRef, useState } from 'react';
import {useHistory} from 'react-router-dom'
import { useObservableCallback, useSubscription } from 'observable-hooks';
import AuthContext from '../context/AuthContext';
import {IAuthContext, IAuthLoginResponse, IAuthRequest} from '../context/AuthContext.d'
import { useEffect } from 'react';

interface IPasswordErrorsProps{
  errors: Array<string>
}
interface SignupProps {
  forceMFA: boolean
}

function PasswordErrors ({errors}:IPasswordErrorsProps){
  return (<>
    {errors.map((error)=>(
      <div>
        {error}
      </div>
    ))}
    </>)
}

function checkText(text:string){
  const findNumbers = /\d/;
  if(findNumbers.test(text)){
    return "Cannot include numbers"
  }
  return null
}

function Signup({forceMFA=false}:SignupProps) {
  const fileInput = useRef<HTMLInputElement>(null)
  const authContext = useContext(AuthContext)
  const history = useHistory();
  const [onSignup, signupChange$] = useObservableCallback(authContext.signup)
  const [onLogin, loginChange$] = useObservableCallback<IAuthLoginResponse, IAuthRequest>(authContext.login)  
  const [email, setEmail] = useState('')
  const [firstNameError, setFirstNameError] = useState<string|null>(null)
  const [lastNameError, setLasttNameError] = useState<string|null>(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [checked, setChecked] = useState(false)

  const [passwordError, setPasswordError] = useState<string|null>(null)  

  const [checkError, setCheckError] = useState<string|null>(null)  
  
  const [pod, setPod] = useState('')
  const [photo, setPhoto] = useState<FileList | null>(null)
  const [loading, setLoading] = useState(false)
  const [userError, setUserError] = useState<string|null>(null)
  const [useMFA, setUseMFA] = useState<boolean>(forceMFA);
  const defaultEmailMessage = "You can use letters, numbers & periods"
  const defaultPassword = "Use 8 or more characters with a mix of letters and numbers"
  const handleSignup = useCallback(()=>{    
    if(!checked){
      setCheckError("Please accept the terms of use.")
      return
    }
    setCheckError(null);
    setFirstNameError(null)
    setLasttNameError(null)
    setUserError(null)
    setPasswordError(null)
    
    const validateFirstName = checkText(firstName);
    if(validateFirstName){
      setFirstNameError(validateFirstName)
      return
    }

    const validateLastName = checkText(lastName);
    if(validateLastName){
      setLasttNameError(validateLastName)
      return
    }

    if(!firstName){
      setFirstNameError("Enter your first name")
      return
    }
    if(!lastName){
      setLasttNameError("Enter your last name")
      return
    }
    if(!email){
      setUserError("Enter an email")
      return
    }
    if(!password){
      setPasswordError("Enter a password")
      return
    }
    if(password != confirm){
      setPasswordError("Password doesn't match")
      return
    }
    setLoading(true)
    onSignup({
      username:null,
      email,
      firstName,
      lastName,
      password,
      pod,
      photo: photo && photo.length > 0 ? photo[0] : undefined,
      useMFA
    })
  },[email, firstName, lastName, password, pod, photo, confirm, checked])
  const handleChecked = useCallback((event)=>{
    setChecked(event.target.checked);
    setCheckError(null);
  },[setChecked, setCheckError])
  const handlePhotoSelect = useCallback(()=>{
    fileInput.current?.click()    
  },[fileInput])
  useSubscription(signupChange$,({error, messages,errorPassword})=>{    
    if(error){
      setLoading(false)      
      if(messages && messages['email']){
        setUserError(messages['email'].join(''))
        return
      }
      setUserError(error)
    }else if(errorPassword){
      setPasswordError(errorPassword)
    }else{
      onLogin({username:email, password})
    }
    
  },()=>{
    setLoading(false)
    setUserError("Error in signup please try again")
  })
  useSubscription(loginChange$,({error})=>{
    setLoading(false)
    if(error){
      setUserError(error)
    }
  })
  useEffect(()=>{
    if(!password || !confirm){
      setPasswordError(null)
      return
    }else if(password != confirm){
      setPasswordError("Password doesn't match")      
    }else{
      setPasswordError(null)
    }
  },[confirm,password])
  const handleLogin = useCallback(()=>{
    history.push('/login')
  },[])

  const passwordErrorElement = passwordError? <PasswordErrors errors={passwordError.split('\n')}/> : null  
  //console.log(forceMFA, useMFA)
  return (
	<>
		<nav className="nav_component">
		  <header>
		    <div className="nav_logo">
		      <img src="/assets/images/Logo.svg" />
		    </div>
			</header>
		</nav>
		<div className="screen_signup">
      <div className="wrapper">
        <h1>Sign up to get started</h1>
        <form>
          <div className="double-field">
            <FormControl margin="normal">
              <TextField error={Boolean(firstNameError)} helperText={firstNameError} label='First Name' value={firstName} onChange={(ev)=>setFirstName(ev.target.value)} variant="outlined" required/>
            </FormControl>
            <FormControl margin="normal">
              <TextField error={Boolean(lastNameError)} helperText={lastNameError} label='Last Name' value={lastName} onChange={(ev)=>setLastName(ev.target.value)} variant="outlined" required/>
            </FormControl>          
          </div>
          <FormControl margin="normal">
            <TextField error={Boolean(userError)} helperText={userError || defaultEmailMessage} label='Email' value={email} onChange={(ev)=>setEmail(ev.target.value)} variant="outlined" required/>
          </FormControl>          
            {/*<FormControl margin="normal">
              <TextField label='Pod' value={pod} onChange={(ev)=>setPod(ev.target.value)} variant="outlined" required/>
            </FormControl>*/}
          {/*
            <Button variant="outlined" className="profile-button"  onClick={handlePhotoSelect} endIcon={<CloudUploadIcon/>}>Upload a profile photo</Button>                    
            <input ref={fileInput} type="file" className="hide" onChange={ev => setPhoto(ev.target.files)}/>
          */}
          <div className="double-field">
            <FormControl margin="normal">
              <TextField error={Boolean(passwordError)} type="password" label='Password' value={password} onChange={(ev)=>setPassword(ev.target.value)} variant="outlined" required/>
            </FormControl>
            <FormControl margin="normal">
              <TextField type="password" label='Confirm Password' value={confirm} onChange={(ev)=>setConfirm(ev.target.value)} variant="outlined" required/>
            </FormControl>
          </div>
          {!Boolean(passwordError) ?
            <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled Mui-required">{defaultPassword}</p>          
          : (
            <div className='MuiFormHelperText-root Mui-error MuiFormHelperText-contained MuiFormHelperText-filled Mui-required'>
              {passwordErrorElement}
            </div>
          )}
          {!forceMFA ?  (
          <div className="mfa">      
            <Checkbox checked={useMFA} onChange={(ev)=>setUseMFA(ev.target.checked)} color="primary"/>          
            <span>Use Multi-Factor Authentication</span>
          </div>): null}
          <div className="terms">            
          <Checkbox
          
        checked={checked}
        onChange={handleChecked}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <span>
            I acknowledge that I agree to the <a target="_blank"  rel="noreferrer" href="https://www.thecarbonauts.com/terms-of-use">Terms of Use</a> and have read the <a target="_blank"  rel="noreferrer" href='https://www.thecarbonauts.com/privacy-policy'>Privacy Policy.</a>
            </span>
          </div>
          {Boolean(checkError) ? (<div className='MuiFormHelperText-root Mui-error MuiFormHelperText-contained MuiFormHelperText-filled Mui-required'>
              {checkError}
            </div> ):null
          }
        </form>
        <Button disabled={loading} variant="contained"  color="primary" onClick={handleSignup} className="primary">
          {loading?
          <>
          <CircularProgress color='inherit' style={{marginRight:10}} size={20} />
          Loading...
          </>
          :"Sign up"}
        </Button>
        <footer>
          Already have an account? <a onClick={handleLogin}>Log in</a>
        </footer>
      </div>
    </div>
	</>
  );
}

export default Signup;