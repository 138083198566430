import { useCallback, useMemo, useState } from "react";
import { ICalculationFootprint, IDashboardRecord } from "../context/CalculatorContext.d";
import FlightIcon from '@material-ui/icons/Flight';
import HomeIcon from '@material-ui/icons/Home';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './DashboardTable.css'
import { ICONS, MAP_LABELS } from "../constant/questions";

type IFilterFunc =  (a:IDashboardRecord, b:IDashboardRecord)=>number
interface IFilters extends Record<FILTER, IFilterFunc> { }

enum FILTER{
  TONS_UP,
  TONS_DOWN,
  NAME_UP,
  NAME_DOWN
}
const MAP_FILTERS:IFilters = {
  [FILTER.TONS_UP]: (a,b)=>{    
    return a.value -b.value
  },
  [FILTER.TONS_DOWN]: (a,b)=>{
    return b.value -a.value
  },
  [FILTER.NAME_UP]: (a,b)=>{
    return a.label.toLowerCase() < b.label.toLowerCase()?1:-1
  },
  [FILTER.NAME_DOWN]: (a,b)=>{
    return a.label.toLowerCase() > b.label.toLowerCase()?1:-1
  },
}

const MAP_ICONS = {
  [ICONS.TRANSPORT]: <FlightIcon className={MAP_LABELS[ICONS.TRANSPORT]}/>,
  [ICONS.HOME]: <HomeIcon className={MAP_LABELS[ICONS.HOME]}/>,
  [ICONS.FOOD]: <FastfoodIcon className={MAP_LABELS[ICONS.FOOD]}/>,
  [ICONS.GOODS]: <ShoppingCartIcon className={MAP_LABELS[ICONS.GOODS]}/>,
  [ICONS.SERVICES]: <SettingsIcon className={MAP_LABELS[ICONS.SERVICES]}/>,
}


export interface IDashboardTableProps{
  table?: IDashboardRecord[]
  total: number
  selected: Record<string,boolean>
}



export default function DashboardTable(props:IDashboardTableProps){
  const [filter, setFilter] = useState<FILTER>(FILTER.TONS_DOWN)  
  const data = props.table || []
  const changeFilterTons = useCallback(()=>{
    if(filter == FILTER.TONS_DOWN){
      return setFilter(FILTER.TONS_UP)
    }
    if(filter == FILTER.TONS_UP){
      return setFilter(FILTER.TONS_DOWN)
    }
    return setFilter(FILTER.TONS_DOWN)
  },[filter,setFilter])

  const changeFilterItem = useCallback(()=>{
    if(filter == FILTER.NAME_UP){
      return setFilter(FILTER.NAME_DOWN)
    }
    if(filter == FILTER.NAME_DOWN){
      return setFilter(FILTER.NAME_UP)
    }
    return setFilter(FILTER.NAME_DOWN)
  },[filter,setFilter])
  const filterSelections= (value:IDashboardRecord,index:number, array:IDashboardRecord[])=>{
    const key = MAP_LABELS[value.icon]
    return props.selected[key]===undefined? true: props.selected[key]
  }
  return (
    <div className="dashboard_table">
      <header>
        <div className="header-item" onClick={changeFilterItem}>
          <span>Emissions 
          Contributor</span>
          {filter == FILTER.NAME_DOWN && (
            <ArrowDownwardIcon/>
          )}
          {filter == FILTER.NAME_UP && (
            <ArrowUpwardIcon/>
          )}
        </div>
        <div className="header-tons" onClick={changeFilterTons}>
          <span>
            Tons of CO₂e/year<br/>
            <span className="total">(% of total)</span>
          </span>
          {filter == FILTER.TONS_DOWN && (
            <ArrowDownwardIcon/>
          )}
          {filter == FILTER.TONS_UP && (
            <ArrowUpwardIcon/>
          )}
        </div>
      </header>
      {data && data.sort(MAP_FILTERS[filter]).filter(filterSelections).map((row, index)=>
        <div key={index} className="row">
          <div className="item">
            {row.label}
          </div>
          <div className="icon">
            {MAP_ICONS[row.icon]}
          </div>
          <div className={`graph ${MAP_LABELS[row.icon]}`} > 
            <div className="line" style={{width: `${row.relation}%`}}></div>
          </div>
          <div className="value">
            {row.value.toFixed(2)}&nbsp;<span>({(row.percent).toFixed(1) }%)</span>
          </div>
        </div>      
      )}
      <footer>            
        <div className="footer-label">
          Table Total:
        </div>
        <div className="footer-value">
          {data ? data.sort(MAP_FILTERS[filter]).filter(filterSelections).reduce((previous,current)=> previous + current.value,0.0).toFixed(2) :0.0
          }
        </div>
      </footer>
    </div>
  )
}