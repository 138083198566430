import React, { useEffect, useContext, useState,Component } from "react";
import {Backdrop, CircularProgress} from '@material-ui/core';
import { useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import AuthContext from '../context/AuthContext';
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
interface AuthorizeWrapperProps{
  children: React.ReactNode
  inverse: boolean
}

export default function AuthorizeWrapper({children,inverse}:AuthorizeWrapperProps) {  
  const [authorized, setAuthorized] = useState(false)
  const history = useHistory()
  const authContext = useContext(AuthContext)
  useSubscription(authContext.authenticate, ({ authenticated }) => {
    if (!authenticated) {        
      if(inverse){
        setAuthorized(true)  
      }else{
        window.location.href = "/login"
      }      
      //history.replace("/login")
      //history.push('/login')
    }else if(authenticated){
      if(inverse){        
        window.location.href = "/"
        //history.replace("/")              
      }else{
        Sentry.addBreadcrumb({
          category: "auth",
          message: "Authentication pass",
          level: Sentry.Severity.Info,
        });
        setAuthorized(true)
      }
    }
  })
  useEffect(()=>{
    authContext.requestAuthenticate()
  },[])
  if(!authorized){
    return ( 
      <Backdrop className={""} open={true} >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }else{    
    return (<React.Fragment>
      {children}
    </React.Fragment>)
  }
}