import { useCallback, useContext } from "react"
import './HintDefault.css'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Tooltip from "./Tooltip";
import { useObservableState } from "observable-hooks";
import CalculatorContext from "../context/CalculatorContext";
interface IHintDefault{
  onClick?: ()=>void
}
export default function HintDefault(props:IHintDefault){
  const calculatorContext = useContext(CalculatorContext)  
  const tooltips = useObservableState(calculatorContext.tooltips$) 
  const addDefault = useCallback(()=>{
    if(props.onClick){
      props.onClick()
    }
  },[props])
  return(
    <section className="question-hint">
      <span>
        Don’t know?&nbsp;<a onClick={addDefault}>Use the average</a> for your area.
      </span>
      {tooltips &&  <Tooltip id="household" title={tooltips['dont_know_defaults'].title} text={tooltips['dont_know_defaults'].text}/>}
    </section>
  )
}