import { TextField,OutlinedInput, FormControl, InputAdornment, Tooltip, withStyles } from "@material-ui/core";
import { ChangeEvent, useEffect, useMemo } from "react";
import { IQuestionType, UNITS } from "../constant/questions";
import { IQuestionHelper } from "../context/CalculatorContext.d";
import Slider, { Mark, ValueLabelProps } from '@material-ui/core/Slider';
import './QuestionSlider.css'

interface IQuestionSliderProps{
  value?: any,
  onChange: (value:any)=>void  
  min?: number,
  max?: number,
  steps?: number,
  defaultValue: number
  format: (value:number)=>string
  formatLabel: (value:number)=>string
}
const CONVERSION_PERIOD:Record<string, number>= {
  "YEAR": 12,
  "MONTH": 1/12
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {    
    fontSize: 14,
  },
}))(Tooltip);
function ValueLabelComponent(props:ValueLabelProps) {
  const { children, open, value, defaultValue } = props;

  return (
    <LightTooltip  open={open} enterTouchDelay={0} placement="top" title={value}>      
      {children}
    </LightTooltip>
  );
}
export default function QuestionSlider(props:IQuestionSliderProps){
  const {value,min, max, steps, defaultValue} = props  
  const onChangeValue = (event:ChangeEvent<{}>, value:any)=>{        
    props.onChange({
      value: value
    })
  }  
  const valueLabelFormat = (value:any)=>{
    return props.format(value)
  }
  const marks = useMemo<Mark[]>(()=>{
    if(max !==undefined&& min !==undefined && steps !==undefined){
      const delta = (max -min) /steps;
      const marks = []
      for(let i=min;i<=max;i+=delta){
        marks.push({
          label: props.formatLabel(i),
          value: i
        })
      }
      return marks
    }
    return []
  },[min,max,steps])  
  return (
    <>      
      <FormControl className="question-slider" >
        <Slider  valueLabelDisplay="on"
                 marks={marks}
                 valueLabelFormat={valueLabelFormat} 
                 value={value?.value !==undefined? value.value :defaultValue} 
                 onChange={onChangeValue} 
                 ValueLabelComponent={ValueLabelComponent}
                 min={min} 
                 max={max} 
                 step={0.1} />
      </FormControl>
    </>
  )
}