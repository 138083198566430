import { ICalculationSection } from "../context/CalculatorContext.d";
import FlightIcon from '@material-ui/icons/Flight';
import HomeIcon from '@material-ui/icons/Home';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import React from "react";
import { Checkbox } from "@material-ui/core";
import './SectionSelection.css'
import { ICONS } from "../constant/questions";
interface ISectionSelectionProps{
  className?: string
  section: ICalculationSection
  selected: boolean
  onChange: (checked:boolean)=> void
  showIcon?: boolean
  showCheckbox?: boolean
  showTotal?: boolean
}

const MAP_ICONS:Record<string,React.ReactChild> = {
  "transport": <FlightIcon className="transport"/>,
  "home": <HomeIcon className="home"/>,
  "food": <FastfoodIcon className="food"/>,
  "goods": <ShoppingCartIcon className="goods"/>,
  "services": <SettingsIcon className="services"/>
}

export default function SectionSelection(props:ISectionSelectionProps){  
  return (
    <div className={"section_selection "+ (props.className || "")}>
      <div className="icon-label">
      {props.showIcon &&
        <div className="icon">
          {MAP_ICONS[props.section.key]}
        </div>
      }
      <div className="label">
        {props.section.key}
      </div>
      </div>
      {props.showTotal &&
      <div className="value">
        {props.section.value.toFixed(2)}
        <span className="percent">
          &nbsp;({props.section.percent.toFixed(1)}%)
        </span>
      </div>
      }
      {props.showCheckbox &&
      <div className="control">
        <Checkbox checked={props.selected} onChange={(ev,checked)=>props.onChange(checked)} color="primary"
        />
      </div>
      }
    </div>
  )
}