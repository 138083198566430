import { TextField,OutlinedInput, FormControl, InputAdornment, MenuItem, Select  } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import NumberFormat from 'react-number-format';
import { IQuestionType, UNITS } from "../constant/questions";
import { IQuestionHelper } from "../context/CalculatorContext.d";
import QuestionHelper,{getPlaceholder} from "./QuestionHelper";
import './QuestionText.css'
interface IQuestionTextProps{
  type: string |undefined
  placeholder?: string,
  helperPlaceholder?: string
  helperPlaceholderUnit?: Record<UNITS,string>  
  value?: any
  onChange: (value:any)=>void
  units?: UNITS
  helpers?: IQuestionHelper[]  
  conversion?: Record<UNITS, number>
  min?: number 
  useDropdown?:boolean
  max?: number
}
const CONVERSION_PERIOD:Record<string, number>= {
  "YEAR": 12,
  "MONTH": 1/12
}

const getHelperKey = (helpers:any, inversePeriod=false):string=>{  
  return Object.keys(helpers).reduce((prev:string,curr:string)=>{   
    if(inversePeriod && curr === 'period') {
      const switchDirtyPeriod = helpers[curr].value === 'MONTH' ?  'YEAR' : 'MONTH'
      return `${prev}_${curr}:${switchDirtyPeriod}`
    }
    return `${prev}_${curr}:${helpers[curr].value}`
  },"")
}

export default function QuestionText(props:IQuestionTextProps){  
  const { type,placeholder,useDropdown, helperPlaceholderUnit, conversion, units,...restProps} = props  
  const [dirtyHelper, onChangeDirtyHelper] = useState<Record<string,any>>({})
  const onChangeValue = (value:any)=>{    
    if(props.type == 'number' && value!=""){
      const min = props.min || 0
      if (Number(value)< min){
        value = min
      }
    }
    props.onChange({
      value,
      helpers: props.value?.helpers
    })
  }
  const selectedHelpers = useMemo<Record<string,any>>(()=>{
    if(props.value?.helpers){
      return props.value.helpers
    }
    if(props.helpers){

      return props.helpers.reduce((prev:Record<string,any>, curr:Record<string,any>)=>{
        prev[curr.key] = {
          value: curr.options[0].value
        }
        return prev
      },{})
    }
    return null
  }, [props.value?.helpers, props.helpers] )

  const onChangeHelper = (helperKey:string, helperValue:any)=>{
    let value = props.value?.value || ""
    if(selectedHelpers){
      const lastHelperDirtyKey = getHelperKey(selectedHelpers)      
      dirtyHelper[lastHelperDirtyKey] = value        
      onChangeDirtyHelper(dirtyHelper)    
    }

    const newHelpersValue = props.value?.helpers ? {...props.value.helpers} : selectedHelpers    
    newHelpersValue[helperKey] = {
      value: helperValue.value
    }
    if(helperKey == 'period'){
      value = (Number(value) * CONVERSION_PERIOD[helperValue.value]).toFixed(2)
    }else{
      let helperDirtyKey = getHelperKey(newHelpersValue)
      if(dirtyHelper[helperDirtyKey]) {
        value = dirtyHelper[helperDirtyKey]
      }else{                        
        helperDirtyKey = getHelperKey(newHelpersValue, true)
        if(dirtyHelper[helperDirtyKey]) {
          const lastDirtyValue = dirtyHelper[helperDirtyKey]
          value = (Number(lastDirtyValue) * CONVERSION_PERIOD[newHelpersValue['period']['value']]).toFixed(2)
        }else{
          value = ''
        }
      }                    
    }    
    props.onChange({
      value,
      helpers: newHelpersValue
    })
  }  
  let helperPlaceholder = props.helperPlaceholder || "";
  if(helperPlaceholder && props.helpers){
    props.helpers.forEach((helper)=>{
      const defaultPlaceholder = getPlaceholder(helper.options[0],units)
      const currentValue = props.value && props.value.helpers ? props.value?.helpers[helper.key] : undefined
      let currentPlaceholder = defaultPlaceholder
      
      if(currentValue ){
        const selectedOption = helper.options.find((h)=>h.value == currentValue.value)
        currentPlaceholder = getPlaceholder(selectedOption,units) || defaultPlaceholder
      }
      
      if(currentPlaceholder){
        helperPlaceholder = helperPlaceholder.replace(`{${helper.key}}`, currentPlaceholder)
      }
    })
  }
  if(helperPlaceholderUnit && units){
    helperPlaceholder = helperPlaceholderUnit[units]
  }    
  const valuesDropdown= useMemo<Array<number>>(()=>{
    if(props.useDropdown && props.min !== undefined && props.max !==undefined){
      let values= []  
      let max = props.useDropdown ? props.max -1 : props.max
      for (let index = props.min; index <= max; index++) {
        values.push(index)            
      }
      return values
    }
    return []
  },[props.min,props.max, props.useDropdown])

  return (
    <>
      {props.helpers && (
        <div className="wrapper-question-helpers">
        {props.helpers.map((helper, index)=>
          <QuestionHelper units={units} value={props.value?.helpers ? props.value?.helpers[helper.key]: helper.options[0].placeholder} 
                          onChange={(value)=>onChangeHelper(helper.key, value)} 
                          key={helper.key} 
                          choices={helper.options}/>
        )}
        </div>
      )}
      <FormControl className="question-text" variant="outlined">
        {useDropdown? (
          <FormControl className="number-vehicles" variant="outlined">
          <Select value={props.value?.value || props.min} displayEmpty={false} onChange={(ev)=>{onChangeValue(ev.target.value)}}>
            <MenuItem value={""} disabled>
              Select a number
            </MenuItem>
            {valuesDropdown.map((index)=>
              <MenuItem key={index} value={index}>{`${index}`}</MenuItem>
            )}     
            <MenuItem value={`${props.max}+`}>{`${props.max}+`}</MenuItem>
          </Select>
        </FormControl>
        ):null}

        {!useDropdown && (type =="number"  ?
        <NumberFormat 
        
          value={props.value?.value || ""}
          customInput={OutlinedInput}
          onValueChange={({ value: v }) => onChangeValue(v)}
          placeholder={placeholder} 
          inputProps={
             {min:props.min || 0}
          }
          thousandSeparator={true}
          endAdornment={helperPlaceholder && <InputAdornment position="end">{helperPlaceholder}</InputAdornment>}
          className="question-text" 
        />:
        <OutlinedInput 
          {...restProps}
          value={props.value?.value || ""}
          onChange={(ev)=>onChangeValue(ev.target.value)}
          type={type}          
          placeholder={placeholder} 
          endAdornment={helperPlaceholder && <InputAdornment position="end">{helperPlaceholder}</InputAdornment>}
          className="question-text" 
          />
        )}

       
      </FormControl>
    </>
  )
}