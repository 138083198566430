
import { useObservableState } from 'observable-hooks';
import { useContext } from 'react';
// @ts-ignore
import Odometer from 'react-odometerjs';
import { Link } from 'react-router-dom';
import CalculatorContext from '../context/CalculatorContext';

import './FootprintScore.css'
import SectionSelection from './SectionSelection';
import Tooltip from './Tooltip';
interface IFootprintScoreProps{
  score: number
  personal?: number
}
export default function FootprintScore({score,personal}: IFootprintScoreProps) {
  const calculatorContext = useContext(CalculatorContext)  
  const tooltips = useObservableState(calculatorContext.tooltips$)  
  return (
    <div className="footprint_score">
      <div className="title">
       Your Carbon Footprint 
      </div>
      <div className="footprint-type">
        <span>
          HOUSEHOLD 
        </span>
        {tooltips && tooltips['household'] &&
          <Tooltip id="household" title={tooltips['household'].title} text={tooltips['household'].text}/>
        }
      </div>
      <div className="score">
        <Link to="/calculator/footprint" className="footprint-link">          
          <Odometer value={score} format="(,ddd).dd" />
        </Link>
      </div>
      <div className="footprint-summary">
        Tons of CO₂e/year&nbsp;
        {tooltips && tooltips['tons'] &&
          <Tooltip id="tons" title={tooltips['tons'].title} text={tooltips['tons'].text}/>
        }
      </div>
      {personal !== undefined &&
        <div className="footprint-personal">
          <div>
            <span className="personal-label">PERSONAL:</span>&nbsp;
            <span className="personal"><Odometer value={personal} format="(,ddd).dd" /></span>&nbsp;
          </div>
          {tooltips && tooltips['personal'] &&
          <Tooltip id="personal" title={tooltips['personal'].title} text={tooltips['personal'].text}/>
        }
        </div>  
           
      }     
    </div>
  )
}