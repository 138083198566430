import { useObservableState } from "observable-hooks";
import { useContext } from "react";
import UserContext from "../context/UserContext";

function Home() {  
  const userContext = useContext(UserContext)
  const user = useObservableState(userContext.user)
  return (
    <div>
      Home
    </div>
  );
}

export default Home;